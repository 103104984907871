<template>
  <div>
    <appBarMobile
      color="primary"
      :show-nav-icon="false"
      :title="title"
    />
    <div class="settings"
    >
      <span
        role="heading"
        style="font-size:20px;"
        class="font-weight-medium settingHeading"
      >Your DoE account</span>
      <div
        role="list"
        class="d-flex flex-column mt-2 mb-2 flex-start"
      >
        <v-list-item
          role="listitem"
          class="settingItem mx-n4"
          to="updateProfile"
        >
          Update profile
        </v-list-item>
        <v-list-item
          role="listitem"
          class="settingItem mx-n4"
          to="changePassword"
        >
          Change DoE password
        </v-list-item>
      </div>
      <div
        class="d-flex flex-row mx-n4 mb-3 justify-space-between"
        style="border-bottom:1px solid #A0A5AE"
      />
      <span
        style="font-size:20px;color:#121212"
        class="font-weight-medium mt-2 settingHeading"
        role="heading"
        aria-level="1"
      >Security</span>
      <div
        role="list"
        class="d-flex flex-row mt-3 justify-space-between"
      >
        <span
          role="listitem"
          class="settingItem"
        >FaceID /Touch Id</span><span
          class="mt-n4 mb-1"
        >
          <v-switch
            class="switchItem"
            aria-label="switch on or off face id or touch id"
            @change="updateSettings()"
            v-model="fingerPrint"
          /></span>
      </div>
      <div
        class="d-flex flex-row mx-n4 mb-3 justify-space-between"
        style="border-bottom:1px solid #A0A5AE"
      />
      <!-- Not supported by API commented <span
        style="font-size:20px;color:#121212"
        class="font-weight-medium mt-2 settingHeading"
      >Notifications</span>
      <div role='list' class="d-flex flex-row mt-3 justify-space-between">
        <span role='heading' class="settingItem">SchoolBiz</span><span role='button' class="mt-n5"><v-switch
          class="switchItem"
          @change="updateSettings()"
          v-model="schoolBiz"
        /></span>
      </div>
      <div
        class="d-flex flex-row mx-n4 mb-3 justify-space-between"
        style="border-bottom:1px solid #A0A5AE"
      /> !-->
      <span
        style="font-size:20px;color:#121212"
        class="font-weight-medium mt-2 settingHeading"
        role="heading"
        aria-level="1"
      >Theme</span>
      <div
        class="d-flex flex-row mt-3 justify-space-between"
      >
        <span
          class="settingItem"
        >Dark</span><span
          class="mt-n4 mb-1"
        ><v-switch
          class="switchItem"
          aria-label="on or off dark theme"
          @change="updateSettings()"
          v-model="theme"
        /></span>
      </div>
      <div
        class="d-flex flex-row mx-n4 mb-3 justify-space-between"
        style="border-bottom:1px solid #A0A5AE"
      />
      <span
        style="font-size:20px;color:#121212"
        class="font-weight-medium mt-2 settingHeading"
        role="heading"
        aria-level="1"
      >Support</span>
      <div
        class="d-flex flex-column flex-start mt-3"
      >
        <span>
          <v-list-item
            role="listitem"
            class="mx-n4 settingItem"
            :to="'/About'"
          >About</v-list-item></span>
        <span>
          <v-list-item
            role="listitem"
            class="mx-n4 settingItem"
            :to="'/Help'"
          >Help</v-list-item></span>
        <span>
          <v-list-item
            role="listitem"
            class="mx-n4 settingItem"
            @click="sendFeedback()"
          >Feedback</v-list-item></span>
        <span>
          <v-list-item
            role="listitem"
            class="mx-n4 settingItem"
            to="./ContactUs"
          >Contact us</v-list-item></span>
        <span>
          <v-list-item
            role="listitem"
            class="mx-n4 settingItem"
            @click="openWindow('https://education.nsw.gov.au/about-us/rights-and-accountability/privacy',5)"
          >Privacy</v-list-item></span>
        <span>
          <v-list-item
            role="listitem"
            class="mx-n4 settingItem"
            to="./Terms"
          >Terms and conditions</v-list-item></span>
        <span
          class="pt-3 settingItem signout"
          @keyup.enter="logoutApp()"
          tabindex="0"
          @click="logoutApp()"
        >Log out of all DoE apps</span>
      </div>
      <div
        role="dialog"
        v-if="askLogout"
      >
        <v-dialog
          retain-focus
          v-model="askLogout"
          width="280"
        >
          <v-card>
            <v-card-title class="settingHeading">
              Are you sure you want to log out?
            </v-card-title>
            <v-card-text class="pb-0">
              <div
                style="width:100%"
                class="pt-0"
              >
                This will log you out of all DoE apps on this device.
              </div>
            </v-card-text>
            <v-card-actions class="d-flex">
              <v-spacer />
              <v-btn
                color="navy"
                class="font-weight-medium align-self-end"
                text
                @click="askLogout = false"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="navy"
                class="font-weight-medium align-self-end logout"
                text
                @click="processLogout"
              >
                LOG OUT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import utils from "@/settings/utils.js";
  import appBarMobile from "@/components/appBarMobile";
  import constant from "@/settings/constants";
  export default {
    components: {
      appBarMobile
    },
    methods:{
      updateSettings(){
        let settings = {figerprintLogin: this.fingerPrint, schoolBiz: this.schoolBiz,darkTheme: this.theme};
        utils.setLocalStorage(this.tokens.user_name,"settings",JSON.stringify(settings));
        if(this.$store.state.settingModule.settings.figerprintLogin != this.fingerPrint){ 
          this.updateSecurity(); }
        this.$store.dispatch('updateSettings',settings);
      },
      updateProfile(){
        this.$store.dispatch('essentialsCookie', { appUrl:constant.UPDATEPROFILE, appNane:'updateProfile',appFrom:'/settings'});
        //eslint-disable-next-line
        NativeJS.profileUpdateTapped();
      },
      logoutApp(){
        this.askLogout = true;
      },
      sendFeedback(){
        //eslint-disable-next-line
        NativeJS.mailTo("feedback.staffportalapp@det.nsw.edu.au");
        //eslint-disable-next-line
        NativeJS.profileFeedbackTapped();
      },
      processLogout(){
        sessionStorage.clear();
        //eslint-disable-next-line
        NativeJS.logout();
        this.askLogout = false;
      },
      openWindow(url,index){
        //eslint-disable-next-line
        NativeJS.openWindow(url,index);
      },
      updateSecurity: function(){
        if(this.fingerPrint){
          //eslint-disable-next-line
          NativeJS.enableSecurity();
        }else{
          //eslint-disable-next-line
          NativeJS.disableSecurity();
        }
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
        settings: 'settings',
        tokens: 'tokens'
      }),
      title(){
        let profileObj = this.$store.state.directoryModule.loginProfile;
        return profileObj && profileObj.first_name && profileObj.last_name ? profileObj.first_name + ' '+ profileObj.last_name: '';
      }
    },
    data(){
      return {
        askLogout: false,
        constant: constant,
        schoolBiz:false,
        fingerPrint: this.settings ? this.settings.figerprintLogin : false,
        theme: false
      }
    },
    created() {
      this.fingerPrint = this.settings.figerprintLogin;
      this.theme = this.settings.darkTheme;
      this.schoolBiz = this.settings.schoolBiz;
    },
    watch:{
      theme: function(){
          this.$vuetify.theme.dark = this.theme;
      }
    }
  }
</script>

<style lang="scss" scoped>
.settingHeading{
  font-size:20px;
  font-weight: 500;
}
.settingItem{
  font-size:14px;
  font-weight: normal;
  a{
    text-decoration: none;
  }
}
::v-deep .v-input--selection-controls, ::v-deep .v-input--selection-controls__input, ::v-deep .v-input--selection-controls__input input[role=switch]{
  height: 48px;
  max-height: 48px;
  width: 48px;
  padding:0;
  margin:0;
}
.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
  color:#121212 !important;
}
.switchItem{
  color:#121212;
}
.signout{
  color:#B81237;
}
.logout{
  color:#B81237 !important;
}
</style>